import request from '@/api/request'


//课程
export function getSyllabusList(data) {
    return request({
        url: '/api/syllabus/getList',
        method: 'GET',
        params: data
    })
}

//删除好友
export function postGroup(data) {
    return request({
        url: '/apiv2/director/postGroup',
        method: 'POST',
        data
    })
}

export function getNews(data) {
    return request({
        url: '/api/news/getList',
        method: 'GET',
        params: data
    })
}

export function getTeachers(data) {
    return request({
        url: '/api/teacher/getList',
        method: 'GET',
        params: data
    })
}

export function getTeacherDetail(data) {
    return request({
        url: '/api/teacher/detail',
        method: 'GET',
        params: data
    })
}

export function getSyllabusDetail(data) {
    return request({
        url: '/api/syllabus/detail',
        method: 'GET',
        params: data
    })
}
