<template>
  <div class="exam-detail">
    <div class="bg"></div>
    <div class="container main">
      <div class="module1">
        <div class="top">
          <div class="label">
            <div class="title">{{ teacher.name }}</div>
          </div>
        </div>
        <div class="container_banner">
          <div class="avatar">
            <img :src="teacher.avatar||lecturer" alt="Avatar" width="200" height="200">
          </div>
          <ul class="nav">
            <li :class="{ 'active': activeTab === 'basic-info' }" @click="activeTab = 'basic-info'">基本信息</li>
            <li :class="{ 'active': activeTab === 'online-courses' }" @click="activeTab = 'online-courses'">在线课程</li>
            <li :class="{ 'active': activeTab === 'offline-courses' }" @click="activeTab = 'offline-courses'">线下课程</li>
            <li :class="{ 'active': activeTab === 'teacher-honors' }" @click="activeTab = 'teacher-honors'">讲师荣誉</li>
          </ul>
        </div>

        <div class="bottom">
          <div class="nums">

          </div>

        </div>
      </div>
      <div class="module2">
        <ul>
          <li>
            <h5>讲师简介</h5>
            <p v-if="teacher.introduction">
              {{ teacher.introduction  }}
            </p>
            <div class="empty-image" v-else>
              <img :src="empty" alt="">
              <p>暂无内容</p>
            </div>

          </li>
          <li>
            <h5>擅长领域</h5>
            <p v-if="teacher.specialties">
              {{ teacher.specialties  }}
            </p>
            <div class="empty-image" v-else>
              <img :src="empty" alt="">
              <p>暂无内容</p>
            </div>
          </li>
          <li>
            <h5>授课方向</h5>
            <p v-if="teacher.direction">
              {{ teacher.direction  }}
            </p>
            <div class="empty-image" v-else>
              <img :src="empty" alt="">
              <p>暂无内容</p>
            </div>
          </li>
          <li>
            <h5>研究领域</h5>
            <p v-if="teacher.field">
              {{ teacher.field  }}
            </p>
            <div class="empty-image" v-else>
              <img :src="empty" alt="">
              <p>暂无内容</p>
            </div>
          </li><li>
            <h5>工作经历</h5>
          <p v-if="teacher.work" v-html="teacher.work">
          </p>
          <div class="empty-image" v-else>
            <img :src="empty" alt="">
            <p>暂无内容</p>
          </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import empty from "@/assets/image/no_audition.png"
import {getTeacherDetail} from "@/api/syllabus/index";
import lecturer from "@/assets/image/lecturer.png";

export default {
  data() {
    return {
      lecturer: lecturer,
      activeTab: 'basic-info',
      empty: empty,
      id: 0,
      teacher: {},
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.fetchData();
  },
  methods: {
    fetchData() {
      var _this = this;
      getTeacherDetail({
        id: _this.id,
      }).then(res => {
        console.log(res);
        if (res.code == 200) {
          _this.teacher = res.data.teacher;
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>

.exam-detail {
  .container_banner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    position: relative;
  }
  .avatar {
    margin-right: 20px;
  }
  .nav {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .nav li {
    font-weight: bold;
    color: black;
    cursor: pointer;
    margin-right: 20px;
  }
  .nav li.active {
    color: blue;
  }
  .bg {
    width: 100%;
    height: 300px;
    background: linear-gradient(90deg, #272f46, rgba(39, 47, 70, .8));;
  }

  .main {
    position: relative;
    left: 50%;
    margin-left: -600px;
    top: -180px;

    .module1 {
      width: 100%;
      background-color: #fff;
      padding: 40px 35px;
      border-radius: 6px;
      box-sizing: border-box;

      .title {
        max-width: 750px;
        font-size: 32px;
        line-height: 48px;
        min-height: 48px;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 10px;
        font-weight: 600;
      }

      .time {
        font-size: 20px;
        color: #666;
      }

      .bottom {
        margin-top: 30px;
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        border-top: 1px solid #e6e6e6;

        .nums {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          width: 700px;
          position: relative;

          .item {
            flex: 1;
            position: relative;
            text-align: center;
            color: #202020;

            p {
              font-size: 36px;
              font-weight: 700;
              color: #266fff;
              margin: 0;
              letter-spacing: -.04em;

              label {
                letter-spacing: -.04em;
                padding-left: 10px;
                font-size: 12px;
                color: #999;
                font-weight: 600;
              }
            }

            span {
              font-size: 14px;
            }

            &::after {
              content: '';
              position: absolute;
              right: 0;
              top: 25px;
              width: 2px;
              height: 10px;
              background: #7b85a7;
            }

            &:last-child:after {
              content: '';
              width: 0;
              height: 0;
            }
          }
        }

        .btn {
          width: 160px;
          height: 50px;
          background: #266fff;
          font-size: 22px;
        }
      }
    }

    .module2 {
      margin-top: 20px;
      width: 100%;
      background-color: #fff;
      padding: 20px 28px;
      border-radius: 6px;
      box-sizing: border-box;

      ul {
        li {
          h5 {
            position: relative;
            margin: 0;
            padding: 20px 12px;
            font-size: 20px;
            font-weight: 550;
            color: #333;

            &:before {
              content: "";
              position: absolute;
              left: 0;
              top: 37%;
              width: 4px;
              height: 20px;
              border-radius: 2px;
              background-color: #266fff;;
            }
          }

          .empty-image {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 0;

            img {
              width: 169px;
              height: 120px;
            }

            p {
              color: #202020;
              font-size: 14px;
              line-height: 22px;
            }
          }

          .rule {
            padding-left: 26px;

            .rule-item {
              font-size: 14px;
              line-height: 22px;
              margin-bottom: 8px;
              position: relative;

              &::before {
                content: '●';
                position: absolute;
                top: 0;
                left: -16px;
                color: #666;
              }

              .times {
                width: 240px;
              }

              .right {
                p {
                  line-height: 24px;
                  margin-bottom: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
